import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Card, Col, Row, Table, Form} from "react-bootstrap";
import {formatDate} from "../../utils/Dates";
import Cookies from "universal-cookie";

export class LiensToDoList extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            toDoListTitle: "Loading Task List...",
            todos: [],
            selectedCategory: this.taskCategory.BILLING
        };
    }

    taskCategory = {
        BILLING: 'BILLING',
        LIENS: 'LIENS'
    };

    timer = null;

    componentDidMount() {
        const cookies = new Cookies();
        let cat = cookies.get("lienTaskCategory");
        if (cat) {
            this.setState({selectedCategory: cat});
        }
        this.loadTaskList();
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    loadTaskList = () => {
        if (this.context.authenticated) {
            this.context.apiRequest("GET", '/liens/task-list', null, true)
                .then(d => {
                    if (d && d.data) {
                        this.setState({todos: d.data, toDoListTitle: "Task List"})
                    }
                })
                .finally(() => {
                    this.timer = setTimeout(this.loadTaskList, 10000);
                });
        }
    }

    categorySelected = (e) => {
        this.setState({selectedCategory: e.target.value});
        const cookies = new Cookies();
        cookies.set("lienTaskCategory", e.target.value, {path: "/"});
    }

    render() {
        let rows = [];
        let compareDt = '';
        this.state.todos.forEach(todo => {
            let cat = this.state.selectedCategory;
            if ((cat === this.taskCategory.BILLING && (
                todo.taskType === 'ATTY_STATUS_CHECK'
                    || todo.taskType === 'ATTY_STATUS_CHECK_FU'
            )) || (cat === this.taskCategory.LIENS && (
                todo.taskType == null
                    || todo.taskType === 'ATTY_SIGNATURE'
                    || todo.taskType === 'SET_UP_SBS'
                    || todo.taskType === 'GET_RECORDS'
                    || todo.taskType === 'GET_RECORDS_FU'
                    || todo.taskType === 'PATIENT_SIGNATURE'
                    || todo.taskType === 'ATTY_SIGNATURE_FU'
                    || todo.taskType === 'ATTY_AUTH_REQUEST'
                    || todo.taskType === 'ATTY_AUTH_REQUEST_FU'
            ))) {

                if(compareDt !== todo.dueDate) {
                    rows.push(<tr key={todo.dueDate}><td colSpan={2} className={"small"}><strong>{todo.dueDate ? formatDate(todo.dueDate) : '[No Due Date]'}</strong></td></tr>);
                    compareDt = todo.dueDate;
                }
                rows.push(
                    <tr key={todo.id || todo.caseId} className={"clickable"} onClick={() => this.props.patientSelected(todo.patientId)}>
                        <td className={"pl-3 small"}>
                            {todo.patientLast}, {todo.patientFirst}
                        </td>
                        <td className={"small"}>{todo.title}</td>
                    </tr>);

            }
        })

        return (
            <Card className={this.props.className}>
                <Card.Header as={Row}>
                    <Col sm={4}>
                        {this.state.toDoListTitle}
                    </Col>
                    <Col sm={8} className={"text-right"}>
                        <Form.Check inline label="Billing" name="taskCategory" type={'radio'}
                                    value={this.taskCategory.BILLING}
                                    checked={this.state.selectedCategory === this.taskCategory.BILLING}
                                    onChange={this.categorySelected}/>
                        <Form.Check inline label="Liens" name="taskCategory" type={'radio'}
                                    value={this.taskCategory.LIENS}
                                    checked={this.state.selectedCategory === this.taskCategory.LIENS}
                                    onChange={this.categorySelected}/>
                    </Col>
                </Card.Header>
                <Card.Body>
                    <Table size={"sm"} hover>
                        <thead>
                        <tr>
                            <th className={"small"}><strong>Due Date/Patient</strong></th>
                            <th className={"small"}><strong>Last</strong></th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    }
}