import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Card, Table} from "react-bootstrap";
import {AlertModal} from "./AlertModal";

export class AlertList extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            listTitle: "Loading...",
            alerts: [],
            modalVisible: false,
            selectedAlert: {
                alertId: 0,
                created: '1970-01-01T00:00:00',
                text: ''
            }
        };
    }

    refreshTimer = 0;

    componentDidMount() {
        this.loadAlerts();
    }

    componentWillUnmount() {
        if (this.refreshTimer) {
            window.clearTimeout(this.refreshTimer);
        }
    }

    loadAlerts = () => {
        if (this.refreshTimer) {
            window.clearTimeout(this.refreshTimer);
        }

        if (this.context.authenticated) {
            this.context.apiRequest("GET", this.props.uri, null, true)
                .then(d => {
                    if (d && d.data) {
                        this.setState({alerts: d.data, listTitle: this.props.title})
                    }
                    this.refreshTimer = window.setTimeout(this.loadAlerts, 30000);
                });
        }
    };

    hideModal = () => {
        this.setState({modalVisible: false});
    };

    showModal = (a) => {
        this.setState({selectedAlert: a}, () => {
            this.setState({modalVisible: true});
        })
    };

    render() {
        return (
            <Card className={this.props.className}>
                <Card.Header>{this.state.listTitle}</Card.Header>
                <Card.Body>
                    <AlertModal alert={this.state.selectedAlert} show={this.state.modalVisible} onCancel={this.hideModal} onDelete={() => {this.hideModal(); this.loadAlerts()}}/>
                    <Table size={"sm"} striped>
                        <tbody>
                        {
                            this.state.alerts.map(a => <tr key={a.alertId} onClick={() => this.showModal(a)} className={"clickable"}><td>
                                {a.alertCode}: {a.patientFirst ? `${a.patientFirst} ${a.patientLast} ${a.externalPatientId}` : ''}
                                {a.groupName || ''}{(a.groupName ? ' (' : '') + (a.providerLast ? a.providerLast + ', ' + a.providerFirst : '') + (a.groupName ? ')' : '')}</td></tr>)
                        }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    }
}