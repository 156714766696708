import React from "react";
import ModalHeader from "react-bootstrap/ModalHeader";
import {Modal, ModalBody} from "react-bootstrap";

export class AdminDashboardModal extends React.Component {

    render() {
        return <Modal size={'xl'} show={this.props.show} onHide={this.props.onHide} scrollable>
            <ModalHeader closeButton>{this.props.title || 'Admin Dashboard'}</ModalHeader>
            <ModalBody>
                {this.props.body}
            </ModalBody>
        </Modal>
    }
}