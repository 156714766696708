import React from "react";
import {Dropdown} from "react-bootstrap";
import {Glyph} from "./Glyph";

export class ContextMenu extends React.Component {
    CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <Glyph className={"parent-hover-child clickable"} name={this.props.glyphName || "three-dots"} variant={this.props.variant || "secondary"}/>
        </a>
    ));

    render() {
        let id = this.props.id || ""+Math.random();
        return <Dropdown>
            <Dropdown.Toggle as={this.CustomToggle} id={"context-menu-"+id}>
                {this.props.buttonText || ""}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    this.props.menuItems &&
                        this.props.menuItems.map((item, i) => <Dropdown.Item key={"contextMenuItem-"+id+"-"+i} onClick={item.onClick}>{item.text}</Dropdown.Item>)
                }
            </Dropdown.Menu>
        </Dropdown>
    }
}