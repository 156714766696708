import React from "react";
import {Accordion, Button, Card} from "react-bootstrap";
import {WorkTaskCard} from "./WorkTaskCard";

export class CollapsibleCardList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            groups: [],
            accordionKey: ''
        }
    }

    componentDidMount() {
        this.regroup();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tasks != this.props.tasks) {
            this.regroup();
        }
    }

    regroup = () => {
        const groupBy = this.props.groupBy;
        const tasks = this.props.tasks || [];
        const groups = [];

        for (const task of tasks) {
            const groupName = groupBy ? task[groupBy] || '' : this.props.header  || '';
            let group = groups.find(o => o.name === groupName);
            if (!group) {
                group = {name: groupName, tasks: []};
                groups.push(group);
            }
            group.tasks.push(task);
        }
        groups.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        if (this.props.sortCardsBy) {
            for (const group of groups) {
                group.tasks.sort((a, b) => (a[this.props.sortCardsBy] || '').localeCompare(b[this.props.sortCardsBy] || ''));
            }
        }
        this.setState({groups});
    }

    accordionSelect = (e) => {
        this.setState({accordionKey: e});
    }

    render() {
        return (
            <>
                <Accordion onSelect={this.accordionSelect} activeKey={this.state.accordionKey} className={this.props.className || ''}>
                    {
                        this.state.groups.map(g => <Card key={g.name}>
                            <Accordion.Toggle as={Card.Header} className={"clickable"} eventKey={g.name}>
                                {g.name} ({g.tasks.length})
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={g.name}>
                                <Card.Body>
                                    {g.tasks.map(t => <WorkTaskCard key={t.id} task={t}
                                                                   onAssignToOther={this.props.onAssignToOther}
                                                                    pendingReasons={this.props.pendingReasons}
                                                                    dismissReasons={this.props.dismissReasons}
                                                                    completeReasons={this.props.completeReasons}
                                                                    showCompleteReasonsForTaskTypes={this.props.showCompleteReasonsForTaskTypes}
                                                                    taggableUsers={this.props.taggableUsers}
                                    />)}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>)
                    }
                </Accordion>
            </>
        );
    }
}
