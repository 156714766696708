export const config = {
    "AUTH": {
        dismissReasons: [
            {value: 'Completed/no action required', label: 'Completed/no action required'},
            {value: 'Order - Duplicate', label: 'Order - Duplicate'},
            {value: 'Chart Deactivated - Patient discharged', label: 'Chart Deactivated - Patient discharged'},
            {value: 'Patient declined order', label: 'Patient declined order'},
            {value: 'Chart Deactivated - Transfer of Care', label: 'Chart Deactivated - Transfer of Care'},
            {value: 'Order - Closed', label: 'Order - Closed'},
            {value: 'Order - Not found', label: 'Order - Not found'},
            {value: 'Accident Case - Medical Lien', label: 'Accident Case - Medical Lien'},
            {value: 'Accident/Injury Case - Worker\'s Compensation', label: 'Accident/Injury Case - Worker\'s Compensation'},
            {value: 'Appointment Cancelled/Rescheduled', label: 'Appointment Cancelled/Rescheduled'},
            {value: 'Order - Expired', label: 'Order - Expired'},
            {value: 'Self Pay', label: 'Self Pay'},
            {value: 'Order - Status Check', label: 'Order - Status Check'},
            {value: 'Unable to process due to insurance guidelines', label: 'Unable to process due to insurance guidelines'}
        ].sort((a,b) => a.label.localeCompare(b.label)),

        pendingReasons: {
            'UPCOMING_APPT_ELIG': [
                {value: 'Authorization', label: 'Authorization'},
                {value: 'Beginning of Month', label: 'Beginning of Month'},
                {value: 'Referral', label: 'Referral'},
                {value: 'Pending Auth Tile', label: 'Pending Auth Tile'},
                {value: 'Clarification', label: 'Clarification'}
            ].sort((a,b) => a.label.localeCompare(b.label)),
            '_DEFAULT': [
                {value: 'With Insurance', label: 'With Insurance'},
                {value: 'Dictation', label: 'Dictation'},
                {value: 'Clarification', label: 'Clarification'},
                {value: 'Referral', label: 'Referral'},
                {value: 'Beginning of Month (BOM)', label: 'Beginning of Month (BOM)'}
            ].sort((a,b) => a.label.localeCompare(b.label))
        },

        completeReasons: [
            // Medicare TPL
            {value: 'Medicare TPL Request Completed - None on file', label: 'Medicare TPL Request Completed - None on file', appliesToTaskTypes: ['MEDICARE_TPL']},
            {value: 'Medicare TPL Request Completed - Accident/Injury Case - Worker\'s Compensation on file', label: 'Medicare TPL Request Completed - Accident/Injury Case - Worker\'s Compensation on file', appliesToTaskTypes: ['MEDICARE_TPL']},
            {value: 'Medicare TPL Request Completed - Accident/Injury Case - MedPay on file', label: 'Medicare TPL Request Completed - Accident/Injury Case - MedPay on file', appliesToTaskTypes: ['MEDICARE_TPL']},
            {value: 'Medicare TPL Request Completed - Medicare Advantage Plan on file', label: 'Medicare TPL Request Completed - Medicare Advantage Plan on file', appliesToTaskTypes: ['MEDICARE_TPL']},
            {value: 'Medicare TPL Request Completed - Medicare Part B Inactive', label: 'Medicare TPL Request Completed - Medicare Part B Inactive', appliesToTaskTypes: ['MEDICARE_TPL']},
            {value: 'Medicare TPL Request Completed - Other Liability Insurance is Primary', label: 'Medicare TPL Request Completed - Other Liability Insurance is Primary', appliesToTaskTypes: ['MEDICARE_TPL']},

            // Precheck
            {value: 'Clarification needed', label: 'Clarification needed', appliesToTaskTypes: ['ELIG_PRECHECK']},
            {value: 'EPO - authorization will be submitted/NP Scheduling Dept to be notified upon authorization approval', label: 'EPO - authorization will be submitted/NP Scheduling Dept to be notified upon authorization approval', appliesToTaskTypes: ['ELIG_PRECHECK']},
            {value: 'Policy Inactive', label: 'Policy Inactive', appliesToTaskTypes: ['ELIG_PRECHECK']},
            {value: 'PPO - no authorization required', label: 'PPO - no authorization required', appliesToTaskTypes: ['ELIG_PRECHECK']}
        ],

        showCompleteReasonsForTaskTypes: ["MEDICARE_TPL", "ELIG_PRECHECK"]
    }
}