import React from 'react';
import {AppHeader} from './AppHeader';
import {AppFooter} from './AppFooter';
import {GlobalContext} from "../GlobalContext";
import {Container, Toast} from "react-bootstrap";
import SchedulingHome from "./scheduling/SchedulingHome";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {MarketingHome} from "./marketing/MarketingHome";
import {LiensHome} from "./liens/LiensHome";
import {AdminHome} from "./admin/AdminHome";
import {RoleWrapper} from "./common/RoleWrapper";
import {ReportsHome} from "./reports/ReportsHome";
import ReportView from "./reports/ReportView";
import {WorkTaskBoard} from "./workTasks/WorkTaskBoard";
import {
    addWsMessageHandler,
    closeWebSocket,
    isWebSocketConnected,
    isWebSocketConnecting,
    wsReconnect
} from "../utils/WebSockets";
import {AuthAdminDashboard} from "./workTasks/AuthAdminDashboard";

export class AppUI extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            notifications: []
        };
    }

    notificationMessageHandler = (msg) => {
        if (msg.event == 'notification') {
            this.context.toast('Notification', msg.data.text, msg.data.iconName, msg.data.iconColor);
            this.setState({notifications: [msg.data, ...this.state.notifications]});
        }
    }

    refreshNotificationsHandler = (msg) => {
        if (msg.event == 'refreshNotifications') {
            this.loadNotifications();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.context.authenticated && this.context.userProfile && this.context.userProfile.userId && !isWebSocketConnected() && !isWebSocketConnecting()) {
            wsReconnect(this.context.userProfile.userId);
            addWsMessageHandler(this.notificationMessageHandler);
            addWsMessageHandler(this.refreshNotificationsHandler);
            this.loadNotifications();
        }
    }

    componentWillUnmount() {
        closeWebSocket();
    }

    loadNotifications() {
        this.context.apiRequest("GET", "/notifications")
            .then(r => {
                if (r && r.data) {
                    this.setState({notifications: r.data});
                }
            })
    }

    authBoardConfig = {
    };
    render() {
        return (
            <Router>
                <Container id="appui" fluid style={{position: 'relative'}}>
                    <Switch>
                        <Route exact={true} path={"/"}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["Scheduling", "Scheduling (Read Only)", "Authorizations", "Medical Assistant"]}><SchedulingHome/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/patients"]}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["Scheduling", "Scheduling (Read Only)", "Authorizations", "Medical Assistant"]}><SchedulingHome/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/marketing"]}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["Scheduling", "Marketing"]}><MarketingHome/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/liens"]}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["Liens"]}><LiensHome/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/authBoard"]}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["System Admin", "Authorizations"]}>
                                    <WorkTaskBoard category={"AUTH"} department={"Auth"} adminDashboard={<AuthAdminDashboard/>}/>
                                </RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/reports"]}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["System Admin","Authorizations","Scheduling","Marketing","Medical Assistant"]}><ReportsHome/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/viewReport/:fileName"]}>
                            <BodyWrapper header={false}>
                                <RoleWrapper roles={["System Admin","Authorizations","Scheduling","Marketing","Medical Assistant"]}><ReportView/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                        <Route path={["/admin"]}>
                            <BodyWrapper header={true} notifications={this.state.notifications}>
                                <RoleWrapper roles={["System Admin", "Supervisor"]}><AdminHome/></RoleWrapper>
                            </BodyWrapper>
                        </Route>
                    </Switch>
                <AppFooter/>
            </Container>
        </Router>
        );
    }
}

export class BodyWrapper extends React.Component {
    render() {
        return <div id="appBody">
            {this.props.header && <AppHeader notifications={this.props.notifications}/>}
            <div style={{overflow: "auto"}}>
                {this.props.children}
            </div>
        </div>
    }
}