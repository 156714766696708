import {GlobalContext} from "../../GlobalContext";
import {Button, Col, Row} from "react-bootstrap";
import {Glyph} from "../common/Glyph";
import {WorkTaskCard} from "./WorkTaskCard";
import React from "react";
import {CollapsibleCardList} from "./CollapsibleCardList";

export class WorkTaskQueue extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            takeNextDisabled: false
        }
    }

    render() {
        let q = this.props.queue;
        let tasks = q.tasks;

        return <Col>
            <Row>
                <Col>
                    <h4 className={"d-inline-block"}>{q.name} ({q.total})</h4>
                </Col>
                {tasks.length > 0 && this.props.showTakeNextButton && this.props.takeNext && <Col className={"text-right"}>
                    <Button size={"sm"} variant={"success"} className={"float-right"}
                            onClick={this.props.takeNext}
                            disabled={this.props.takeNextButtonDisabled || this.state.takeNextDisabled}
                        >
                        Will Work On It <Glyph name={"arrow-right"}/>
                    </Button></Col>}
            </Row>
            {
                q.groupTasksBy ? <CollapsibleCardList tasks={tasks}
                                                      className={"mb-3"}
                                                      groupBy={q.groupTasksBy}
                                                      sortGroupsBy={q.groupTasksBy}
                                                      sortTasksBy={this.props.sortTasksBy}
                                                      onAssignToOther={this.props.onAssignToOther}
                                                      taggableUsers={this.props.taggableUsers}
                    />
                    :
                    tasks.map(t => <WorkTaskCard key={t.id} task={t}
                                                 onAssignToOther={this.props.onAssignToOther}
                                                 taggableUsers={this.props.taggableUsers}
                    />)
            }
        </Col>
    }
}