import React from "react";
import {GlobalContext} from "../../GlobalContext";
import {Alert, Badge, Button, Col, Container, Row, Spinner} from "react-bootstrap";
import {RoleWrapper} from "../common/RoleWrapper";
import SelectUserModal from "../common/SelectUserModal";
import {Glyph} from "../common/Glyph";
import {formatDateTime} from "../../utils/Dates";
import WorkTaskModeModal from "./WorkTaskModeModal";
import WorkTaskStatsModal from "./WorkTaskStatsModal";
import WorkTaskSearchModal from "./WorkTaskSearchModal";
import {addWsMessageHandler, removeWsMessageHandler, wsReconnect} from "../../utils/WebSockets";
import {WorkTaskQueue} from "./WorkTaskQueue";
import {AdminDashboardModal} from "./AdminDashboardModal";

export class WorkTaskBoard extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            backlog: [],
            userTasks: [],
            pendingUserTasks: [],
            completedTasks: [],
            teamTasks: [],
            deptUsers: [],
            teamStats: [],
            lastImport: null,
            showUsersModal: false,
            selectedTask: null,
            dataTimestamp: 0,
            showModeDialog: false,
            showStatsDialog: false,
            showSearchDialog: false,
            loading: false,
            backlogCount: 0,
            fullRefreshTimer: null,
            queues: [],
            queuesLoading: new Set(),
            takeNextDisabled: false,
            assignedCountPending: false,
            showAdminDialog: false
        };
    }

    fullRefresh = () => {
        console.log('Have not received WS message after 3 sec. Reconnecting WS and doing full refresh.');
        wsReconnect();
        this.refresh();
        this.clearFullRefreshTimer();
    }

    setFullRefreshTimer = () => {
        this.clearFullRefreshTimer();
        this.setState({fullRefreshTimer: window.setTimeout(this.fullRefresh, 3000)});
    }

    clearFullRefreshTimer = () => {
        if (this.state.fullRefreshTimer) {
            window.clearTimeout(this.state.fullRefreshTimer);
        }
        this.setState({fullRefreshTimer: null});
    }

    wsMsgHandler = (msg) => {
        switch (msg.event) {
            case `workTask.${this.props.category}.fullRefresh`:
                this.refresh();
                this.clearFullRefreshTimer();
                break;
            case `workTaskBoard.${this.props.category}.teamStats.replace`:
                this.setState({teamStats: msg.stats});
                break;
            case 'bomModeChanged':
                this.setState({bomMode: msg.value}, this.refresh);
                break;
            case 'refreshQueue':
                this.clearFullRefreshTimer();
                this.loadTasks(msg.queueId);
                break;
        }
    };

    componentDidMount() {
        this.refresh();
        this.context.apiRequest('GET', `/users?department=${encodeURIComponent(this.props.department)}&activeOnly=true`)
            .then(resp => {
                if (resp && resp.data) {
                    this.setState({deptUsers: resp.data});
                }
            })

        addWsMessageHandler(this.wsMsgHandler);
    }

    componentWillUnmount() {
        // remove ws message listener
        removeWsMessageHandler(this.wsMsgHandler);
    }

    refresh = () => {
        if (!this.context.authenticated) return;

        if (this.state.queues.length == 0) {
            this.context.apiRequest('GET', '/workTasks/queues')
                .then(resp => {
                    if (resp && resp.data) {
                        let qs = resp.data.filter(q => q.roles.length == 0 || this.context.hasAnyRole(q.roles));
                        this.setState({
                            queues: qs.map(q => ({...q, tasks: [], total: 0}))
                        }, () => {
                            // load the queues after setting initial state
                            qs.forEach(q => this.loadTasks(q.id));
                        });
                    }
                });
        } else {
            this.state.queues.forEach(q => this.loadTasks(q.id));
        }

        // fetch leaderboard
        this.context.apiRequest('GET', `/workTasks/teamStats?category=${this.props.category}`)
            .then(resp => {
                if (resp && resp.data) {
                    this.setState({teamStats: resp.data});
                }
            });

        // get current mode
        this.context.apiRequest('GET', `/workTasks/currentMode?category=${this.props.category}`)
            .then(resp => {
                if (resp && resp.data) {
                    this.setState({currentMode: resp.data.mode});
                }
            });

    }

    loadTasks = (queueId) => {
        if (this.state.queuesLoading.has(queueId)) {
            console.log(`queue ${queueId} already loading, returning`);
            return;
        }

        let loading = new Set(this.state.queuesLoading);
        loading.add(queueId);
        this.setState({queuesLoading: loading}, () => {

            this.context.apiRequest('GET', `/workTasks?queueId=${queueId}`)
                .then(resp => {
                    let newQs = [...this.state.queues];
                    let queue = newQs.find(n => n.id == queueId);
                    if (queue && resp.data) {
                        queue.tasks = resp.data.tasks;
                        queue.total = resp.data.total;
                        this.setState({queues: newQs});
                    }
                })
                .finally(() => {
                    this.queueTasksLoaded(queueId);
                });
        });
    }

    takeNext = () => {
        if (this.state.takeNextDisabled) return;

        this.setState({takeNextDisabled: true}, () => {
            // server will choose next task and push update via websocket
            this.context.apiRequest('POST', `/workTasks/assignFirst`);

            // in case of network/socket issues, trigger a reconnect and full refresh after 3 seconds if we
            // don't get a message over the websocket.
            if (this.props.expectingWsMessage) this.props.expectingWsMessage();
        });
    }

    checkMyAssignedCount = () => {
        let assignedTasks = new Set();
        this.state.queues.forEach(q => {
            if (q.tasks) {
                q.tasks.forEach(t => {
                    if (t.assigneeUserId == this.context.userProfile.userId && t.status === 'ASSIGNED') {
                        assignedTasks.add(t.id);
                    }
                });
            }
        });

        if (assignedTasks.size > 4 && !this.state.takeNextDisabled) {
            this.setState({takeNextDisabled: true});
        } else if (this.state.takeNextDisabled && assignedTasks.size <= 4) {
            this.setState({takeNextDisabled: false});
        }
    }

    queueTasksLoaded = (queueId) => {
        let loading = new Set(this.state.queuesLoading);
        loading.delete(queueId);
        this.setState({queuesLoading: loading});
        if (loading.size == 0) {
            // no queues awaiting task loading - check counts
            this.checkMyAssignedCount();
        }
    }

    showUsersModal = (task) => {this.setState({showUsersModal: true, selectedTask: task});}
    hideUsersModal = () => {this.setState({showUsersModal: false, selectedTask: null});}

    userSelected = (user) => {
        let task = this.state.selectedTask;
        if (task) {
            this.context.apiRequest('POST', `/workTasks/${task.id}/assign`, {userId: user.userId});
        }
        this.hideUsersModal();
    }

    render() {
        let otherUsers = this.state.deptUsers.filter(u => u.userId != this.context.userProfile.userId);
        let queues = this.state.queues;
        let qGrid = [];

        for (const q of queues) {
            if (Array.isArray(q.displayOrder)) {
                let col = qGrid[q.displayOrder[0]];
                if (!col) {
                    col = [];
                    qGrid[q.displayOrder[0]] = col;
                }
                col[q.displayOrder[1]] = q;
            } else {
                qGrid[q.displayOrder] = [q];
            }
        }

        return (
            <Container fluid className={'work-task-board'}>
                <SelectUserModal department={this.props.department} show={this.state.showUsersModal} onOk={this.userSelected} onCancel={this.hideUsersModal} role={"Authorizations"}/>
                {(this.state.lastImport || this.state.teamStats) && <Alert variant={"secondary"}>
                    <Row>
                        <Col sm={"auto"}>
                            <small>Today's Leaders:</small>
                        </Col>
                        <Col>
                            {
                                this.state.teamStats.map((s,i) => <Badge key={s.userId} style={s.customColor && {backgroundColor: s.customColor, color: 'white'}} variant={s.customColor ? null : "dark"} className={"ml-2"}>
                                    #{i+1} {s.iconName && <Glyph name={s.iconName}/>} {s.firstName} {s.lastName.charAt(0)}: {s.closedToday}
                                </Badge>)
                            }
                        </Col>
                        <Col className={"text-right"} sm={"auto"}>
                            {this.state.queuesLoading.size > 0 && <Spinner animation={"border"} size={"sm"}/>}
                            {
                                this.state.lastImport && <small className={"text-secondary ml-3"}>Last import: {formatDateTime(this.state.lastImport)}</small>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={"auto"}></Col>
                        <Col className={"text-center"}></Col>
                        <Col className={"text-right"} sm={"auto"}>
                            {this.state.currentMode && this.state.currentMode != "None" && <small>Work Mode: <b>{this.state.currentMode}</b></small>}
                            <RoleWrapper roles={["Supervisor", "System Admin"]}>
                                {
                                    this.props.adminDashboard ?
                                        <>
                                            <AdminDashboardModal show={this.state.showAdminDialog} onHide={() => this.setState({showAdminDialog: false})} body={this.props.adminDashboard} title={this.props.adminDashboardTitle}/>
                                            <Button variant={"link"} className={"p-0 ml-3"} size={"sm"} onClick={() => this.setState({showAdminDialog: true})}>Admin Dashboard</Button>
                                        </>
                                    : <></>
                                }
                                <Button variant={"link"} className={"p-0 ml-3"} size={"sm"} onClick={() => this.setState({showSearchDialog: true})}>Search</Button>
                                <WorkTaskSearchModal category={this.props.category} show={this.state.showSearchDialog} onHide={() => this.setState({showSearchDialog: false})}/>
                            </RoleWrapper>
                            <Button variant={"link"} className={"p-0 ml-3"} size={"sm"} onClick={() => this.setState({showModeDialog: true})}>Assign Modes</Button>
                            <WorkTaskModeModal category={this.props.category} department={this.props.department} show={this.state.showModeDialog} onHide={() => this.setState({showModeDialog: false})}/>
                            <Button variant={"link"} className={"p-0 ml-3"} size={"sm"} onClick={() => this.setState({showStatsDialog: true})}>Stats</Button>
                            <WorkTaskStatsModal category={this.props.category} department={this.props.department} taskTypes={['UPCOMING_APPT_ELIG']} show={this.state.showStatsDialog} onHide={() => this.setState({showStatsDialog: false})}/>
                        </Col>
                    </Row>
                </Alert>}
                <Row>
                    {
                        qGrid.map((col, i) => <Col key={'qCol'+i} style={{maxHeight: 'calc(100vh - 200px', overflowY: 'auto'}}>
                            {
                                col.map((q, j) => <Row key={'q'+q.id}><WorkTaskQueue
                                                                   queue={q}
                                                                   onAssignToOther={this.showUsersModal}
                                                                   showTakeNextButton={i == 0}
                                                                   takeNextButtonDisabled={this.state.takeNextDisabled}
                                                                   expectingWsMessage={this.setFullRefreshTimer}
                                                                   taggableUsers={otherUsers}
                                                                   onTasksLoaded={() => this.queueTasksLoaded(q.id)}
                                                                   takeNext={this.takeNext}
                                /></Row>)
                            }
                        </Col>)
                    }
                </Row>
            </Container>
        );
    }
}