import React from 'react';
import {GlobalContext} from "../../GlobalContext";
import {Card, Table} from "react-bootstrap";
import {Link} from 'react-router-dom';

export class SchedulingToDoList extends React.Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.state = {
            toDoListTitle: "Loading To-Do List...",
            todos: []
        };
    }

    timer = null;

    componentDidMount() {
        this.loadTaskList();
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    loadTaskList = () => {
        if (this.context.authenticated) {
            this.context.apiRequest("GET", '/scheduling/to-do-list', null, true)
                .then(d => {
                    if (d) {
                        this.setState({todos: d.data, toDoListTitle: "Scheduling To-Do List"})
                    }
                })
                .finally(() => {
                    if (this.context.authenticated) {
                        this.timer = setTimeout(this.loadTaskList, 5000);
                    }
                });
        }
    }

    render() {
        let rows = [];
        let compareDt = '';
        this.state.todos.forEach(todo => {
            if(compareDt !== todo.dueDate) {
                rows.push(<tr key={todo.dueDate}><td colSpan={3} className={"small"}><strong>{todo.dueDate}</strong></td></tr>);
                compareDt = todo.dueDate;
            }
            rows.push(
                <tr key={todo.referralId}>
                    <td className={"pl-3 small"}>
                        <Link className="text-dark" replace={true} to={`/patients/${todo.patientId}`}>{todo.lastName}, {todo.firstName}</Link>
                    </td>
                    <td className={"small"}>{todo.owner || ''}</td>
                    <td className={"small"}>{todo.activityContactTypeCode}</td>
                </tr>);
        })

        return (
            <Card className={this.props.className || "dashboardSideBox"}>
                <Card.Header>{this.state.toDoListTitle}</Card.Header>
                <Card.Body>
                    <Table size={"sm"}>
                        <thead>
                        <tr>
                            <th className={"small"}><strong>Due Date/Patient</strong></th>
                            <th className={"small"}><strong>Owner</strong></th>
                            <th className={"small"}><strong>Last</strong></th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        );
    }
}